<template>
  <div class="pf-container">
    <span class="pf-trigger"></span>
    <div class="photoFocus">
      <div class="pf-bg" v-lazy:background-image="this.urlPrinc + imgUrlinit" id="pf-init"></div>
      <div class="pf-bg" v-lazy:background-image="this.urlPrinc + imgUrltake" id="pf-take"></div>
      <div class="pf-bg" v-lazy:background-image="this.urlPrinc + imgUrlblur" id="pf-blur"></div>
      <div class="pf-svg">
        <svg version="1.1" id="tracesvg" width="170" height="127">
          <path stroke="#bf5f00" d="m95.08739,23.5565c0,0 -0.97521,-2.57404 -12.11484,-4.46336c-12.58862,-2.13507 -26.23361,-1.74608 -48.45937,0.63762c-12.19499,1.30791 -19.89258,4.22934 -23.59206,6.37623c-1.98841,1.15392 -2.90051,2.43191 -3.82574,5.73861c-1.04508,3.73506 -1.59373,8.90504 -1.91287,15.94058c-0.31783,7.00665 -1.36697,13.59474 0.63762,22.31681c1.53824,6.69292 5.58086,14.13607 9.56435,19.76632c5.52411,7.80775 10.94535,13.97056 17.85345,19.76632c5.96261,5.00252 14.44694,8.58056 26.14255,10.8396c11.33828,2.19 22.98782,2.88007 36.98215,1.91287c23.11962,-1.59786 38.02706,-7.43828 50.37224,-17.21583c10.60323,-8.39792 16.57196,-20.21469 17.85345,-33.15641c1.31942,-13.32492 -5.67745,-30.75419 -24.22968,-46.5465c-19.25681,-16.39206 -33.07139,-20.72337 -42.08313,-21.04157c-8.9439,-0.31581 -15.94058,3.82574 -21.04157,6.37623l-1.27525,0.63762" id="p1" stroke-width="4.5" fill="none"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgUrlinit: '/img/pf-init.jpg',
      imgUrltake: '/img/pf-take.png',
      imgUrlblur: '/img/pf-blur.jpg'
    }
  },
  mounted () {
    this.$nextTick(this.focusImg)
  },
  methods: {
    /**
     * Methode pour le focus image avec dessin
     */
    focusImg () {
      /**
       * Fonction permettant de préparer le contour du svg a animer
       * @param el - element svg
       * @returns {number}
       */
      function pathPrepare (el) {
        let lineLenght = el.getTotalLength()
        el.style.strokeDasharray = lineLenght
        el.style.strokeDashOffset = lineLenght
        return lineLenght
      }

      // Variables
      let animateElem = document.getElementById('pf-init')
      let animateElem2 = document.getElementById('pf-take')
      let animateElem3 = document.getElementById('pf-blur')
      let dessin = document.getElementById('p1')
      let lineLenght = pathPrepare(dessin)
      let vm = this.$ksvuescr.$gsap.TweenMax

      // Animations
      let tween = vm.to(animateElem, 1, { zIndex: '-1' }, 0)
      let tween2 = vm.to(animateElem2, 1, { zIndex: '3', opacity: 1 }, 0)
      let tween3 = vm.to(animateElem3, 1, { zIndex: '1' }, 0)
      let tween4 = vm.from(dessin, 1, { strokeDashoffset: lineLenght, delay: 0.5 })
      let trigElem = '.pf-trigger'

      // Scenes
      const scene = this.createScene(trigElem, 1500, tween)
      const scene2 = this.createScene(trigElem, 100, tween2)
      const scene3 = this.createScene(trigElem, 1500, tween3)
      const scene4 = new this.$scrollmagic.Scene({
        triggerElement: trigElem,
        duration: 550,
        tweenChanges: true
      })
        .setTween(tween4)

      // Ajout des scenes
      this.$ksvuescr.$emit('addScene', 'focusImgScene', scene)
      this.$ksvuescr.$emit('addScene', 'focusImgScene', scene2)
      this.$ksvuescr.$emit('addScene', 'focusImgScene', scene3)
      this.$ksvuescr.$emit('addScene', 'focusImgScene', scene4)
    }
  },
  destroyed () {
    // Destroy ScrollMagic when our component is removed from DOM
    this.$ksvuescr.$emit('destroy')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../assets/styles/function';

  .photoFocus{
    position: relative;
    min-height: 70vh;
    width: 100%;
  }
  .pf-bg{
    position: absolute;
    width: 100%;
    min-height: 70vh;
    /*transition: opacity 0.5s ease-in-out;*/
  }
  #pf-init{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 3;
  }
  #pf-take{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 2;
  }
  #pf-blur{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
  }
  .pf-svg{
    position: absolute;
    z-index: 10;
    top: 70%;
    left: 40%;
    transform: scale(0.5);
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width:768px) {
    .pf-svg{
      top: 80%;
      left: 55%;
      transform: scale(1);
    }
    .photoFocus, .pf-bg{
      min-height: 100vh;
    }
  }
  //                      __   _  ___ ____  _  _     __
  //                     | _| / |/ _ \___ \| || |   |_ |
  //    _____ _____ _____| |  | | | | |__) | || |_   | |_____ _____ _____
  //   |_____|_____|_____| |  | | |_| / __/|__   _|  | |_____|_____|_____|
  //                     | |  |_|\___/_____|  |_|    | |
  //                     |__|                       |__|
  @media (min-width: 1024px) {
    .pf-svg{
      top: 75%;
      left: 50%;
    }
  }
  @media (min-width: 1024px) and (max-height: 1366px){
    .pf-svg{
      top: 80%;
      left: 60%;
    }
  }
  //                      __   _ ____  ___   ___    __
  //                     | _| / |___ \( _ ) / _ \  |_ |
  //    _____ _____ _____| |  | | __) / _ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |/ __/ (_) | |_| |  | |_____|_____|_____|
  //                     | |  |_|_____\___/ \___/   | |
  //                     |__|                      |__|
  @media (min-width:1280px) {
    .pf-svg{
      top: 80%;
      left: 50%;
    }
  }
  //                      __   _  ___ ____   ___    __
  //                     | _| / |/ _ \___ \ / _ \  |_ |
  //    _____ _____ _____| |  | | (_) |__) | | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |\__, / __/| |_| |  | |_____|_____|_____|
  //                     | |  |_|  /_/_____|\___/   | |
  //                     |__|                      |__|
  @media (min-width: 1920px){
    .pf-svg{
      top: 85%;
      left: 55%;
    }
  }
</style>
