<template>
  <div class="s-container">
    <span class="s-coll-trigger"></span>
    <div class="separatorCollAnimation">
      <div class="s-bg" v-lazy:background-image="this.urlPrinc + imgUrltop" id="s-coll-top"></div>
      <div class="s-bg" v-lazy:background-image="this.urlPrinc + imgUrlinit" id="s-coll-init"></div>
      <div class="s-bg" v-lazy:background-image="this.urlPrinc + imgUrlbot" id="s-coll-bot"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeparatorCollAnimation',
  data () {
    return {
      imgUrltop: '/img/s-coll-top.png',
      imgUrlinit: '/img/s-coll-init.png',
      imgUrlbot: '/img/s-coll-bot.png'
    }
  },
  mounted () {
    this.$nextTick(this.separatorAnimation)
  },
  methods: {
    separatorAnimation () {
      let m768 = window.matchMedia('(min-width: 768px)')
      m768.addListener(f768)

      let trig = '.s-coll-trigger'
      let vm = this.$ksvuescr.$gsap.TweenMax

      let anime1 = document.getElementById('s-coll-top')
      let anime2 = document.getElementById('s-coll-bot')

      function ecouteur (listenerMedia, vm, animateElem, etat, x, y, etat2, functionPrec) {
        let tween = null
        if (listenerMedia.matches) {
          tween = vm.to(animateElem, 0.1, { backgroundPosition: etat + x + 'px ' + etat2 + y + 'px', opacity: 1 }, 0)
        } else {
          if (typeof functionPrec === 'function') {
            return functionPrec(vm, animateElem, etat)
          }
        }
        return tween
      }

      function init (vm, animateElem, etat, etat2) {
        if (etat === '-') {
          return vm.to(animateElem, 1, { backgroundPosition: '-50px 50px', opacity: 1 })
        } else {
          return vm.to(animateElem, 1, { backgroundPosition: '50px -50px', opacity: 1 })
        }
      }
      function f768 (vm, animateElem, etat, etat2) {
        return ecouteur(m768, vm, animateElem, etat, 150, 150, etat2, init)
      }
      function fAnime (vm, animateElem, etat, etat2) {
        return f768(vm, animateElem, etat, etat2)
      }

      let tw1 = fAnime(vm, anime1, '-', '')
      let tw2 = fAnime(vm, anime2, '', '-')

      // create scene and set its params
      const scene = this.createScene(trig, 300, tw1)
      const scene2 = this.createScene(trig, 300, tw2)

      // Add scene to ScrollMagic controller by emiting an 'addScene' event on vm.$ksvuescr (which is our global event bus)
      this.$ksvuescr.$emit('addScene', 'separatorCollAnimation', scene)
      this.$ksvuescr.$emit('addScene', 'separatorCollAnimation', scene2)
    }
  },
  destroyed () {
    // Destroy ScrollMagic when our component is removed from DOM
    this.$ksvuescr.$emit('destroy')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../assets/styles/function';

  .separatorCollAnimation{
    position: relative;
    min-height: 50vh;
    width: 100%;
    margin: 0 auto;
  }
  .s-bg{
    position: absolute;
    width: 100%;
    min-height: 100vh;
  }
  #s-coll-top{
    @include bgContainSubFolder('s-coll-top', 'png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 0;
    opacity: 0;
    z-index: 3;
  }
  #s-coll-init{
    @include bgContainSubFolder('s-coll-init', 'png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 0;
    z-index: 2;
  }
  #s-coll-bot{
    @include bgContainSubFolder('s-coll-bot', 'png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 0;
    opacity: 0;
    z-index: 1;
  }
  //                      __   _____  __    ___    __
  //                     | _| |___ / / /_  / _ \  |_ |
  //    _____ _____ _____| |    |_ \| '_ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |   ___) | (_) | |_| |  | |_____|_____|_____|
  //                     | |  |____/ \___/ \___/   | |
  //                     |__|                     |__|
  @media (min-width:360px) {
    .separatorCollAnimation{
      min-height: 55vh;
    }
  }
  @media (min-width:360px) and (min-height: 740px){
    .separatorCollAnimation{
      min-height: 45vh;
    }
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width: 768px) {
    .separatorCollAnimation{
      min-height: 65vh;
    }
  }
  //                      __   _  ___ ____  _  _     __
  //                     | _| / |/ _ \___ \| || |   |_ |
  //    _____ _____ _____| |  | | | | |__) | || |_   | |_____ _____ _____
  //   |_____|_____|_____| |  | | |_| / __/|__   _|  | |_____|_____|_____|
  //                     | |  |_|\___/_____|  |_|    | |
  //                     |__|                       |__|
  @media (min-width: 1024px) {
    .separatorCollAnimation{
      min-height: 100vh;
    }
  }
  @media (min-width: 1024px) and (min-height: 1366px){
    .separatorCollAnimation{
      min-height: 60vh;
    }
  }
  //                      __   _ ____  ___   ___    __
  //                     | _| / |___ \( _ ) / _ \  |_ |
  //    _____ _____ _____| |  | | __) / _ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |/ __/ (_) | |_| |  | |_____|_____|_____|
  //                     | |  |_|_____\___/ \___/   | |
  //                     |__|                      |__|
  @media (min-width: 1280px) {
    .separatorCollAnimation{
      margin-left: 25%;
    }
  }
</style>
