import { render, staticRenderFns } from "./Comparaison.vue?vue&type=template&id=5870e7e6&scoped=true&"
import script from "./Comparaison.vue?vue&type=script&lang=js&"
export * from "./Comparaison.vue?vue&type=script&lang=js&"
import style0 from "./Comparaison.vue?vue&type=style&index=0&id=5870e7e6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5870e7e6",
  null
  
)

component.options.__file = "Comparaison.vue"
export default component.exports