<template>
  <div class="vo-container">
    <span class="vo-trigger"></span>
    <div class="vocaleAnime">
      <div class="vo-bg" v-lazy:background-image="this.urlPrinc + imgUrl" id="vo-init"></div>
      <div class="vo-svg">
        <svg id="tracesvg2" width="108" height="148" >
          <path fill="#fff" stroke-width="1.5" d="m-119.58693,101.53519zm138.61074,-63.13832c-3.94511,-2.78973 -10.27936,-2.78973 -14.20439,0.01434c-3.91499,2.80408 -3.91499,7.34367 0.02008,10.15492l0,-0.01434c8.66317,6.19622 14.00362,14.69451 14.00362,24.13943c0,9.43776 -5.33041,17.90736 -13.98354,24.10358c-3.95514,2.78973 -3.95514,7.32932 -0.02008,10.14774c1.9575,1.39845 4.52733,2.10126 7.09718,2.10126c2.57988,0 5.14972,-0.70281 7.10721,-2.10126c12.26697,-8.74929 19.87611,-20.90507 19.86606,-34.25132c0.01004,-13.37494 -7.62921,-25.54506 -19.88614,-34.29435l0,0zm23.03821,-16.45153c-3.94511,-2.80408 -10.27936,-2.80408 -14.18431,0c-3.93507,2.80408 -3.93507,7.35084 0,10.14057c14.55573,10.40592 23.53009,24.72033 23.53009,40.59814c0,15.86347 -8.97436,30.17071 -23.51002,40.5838c-3.93507,2.7969 -3.93507,7.33649 0,10.14057c1.9575,1.39845 4.52733,2.10126 7.10721,2.10126c2.56984,0 5.13968,-0.70281 7.09718,-2.10126c18.14949,-12.97333 29.40258,-30.94524 29.3825,-50.72437c0.01004,-19.79348 -11.24305,-37.77972 -29.42266,-50.73871l0,0zm21.87375,-15.62681c-3.94511,-2.80408 -10.2894,-2.78973 -14.19435,0.01434c-3.92503,2.78973 -3.92503,7.33649 0.02008,10.1334l-0.02008,0c20.16722,14.40764 32.60484,34.22981 32.60484,56.21778c0,21.96646 -12.43762,41.79579 -32.58477,56.2106c-3.92503,2.7969 -3.92503,7.33649 0.02008,10.14774c1.94745,1.39128 4.5173,2.08692 7.08713,2.08692s5.14972,-0.70281 7.10721,-2.10126c23.72083,-16.96788 38.45725,-40.46905 38.43718,-66.344c0.02008,-25.89647 -14.71635,-49.4048 -38.47733,-66.36552l0,0z" id="svg_1"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgUrl: '/img/vocale.jpg'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../assets/styles/function';
  @import '../../assets/styles/vars';

  .vocaleAnime{
    position: relative;
    min-height: 50vh;
    width: 100%;
  }
  .vo-bg{
    min-height: 50vh;
  }
  #vo-init{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  @keyframes animeSound {
    0%{
      opacity: 1;
      transform: scale(0);
    }
    80%{
      opacity: 0;
      transform: scale(0.7);
    }
    100%{
      opacity: 0;
      transform: scale(0);
    }
  }
  @keyframes animeSound {
    0%{
      opacity: 1;
      transform: scale(0);
    }
    80%{
      opacity: 0;
      transform: scale(0.7);
    }
    100%{
      opacity: 0;
      transform: scale(0);
    }
  }
  .vo-svg{
    position: absolute;
    top: 41%;
    left: 17%;
    transform: scale(0.5) rotate(20deg);
    transform-origin: center center;
    filter: drop-shadow( -5px -5px 10px $color-beta );

    path:nth-child(1){
      animation: animeSound 5s linear infinite;
    }
  }
  //                      __   _  _   _ _  _     __
  //                     | _| | || | / | || |   |_ |
  //    _____ _____ _____| |  | || |_| | || |_   | |_____ _____ _____
  //   |_____|_____|_____| |  |__   _| |__   _|  | |_____|_____|_____|
  //                     | |     |_| |_|  |_|    | |
  //                     |__|                   |__|
  @media (min-width:414px) {
    .vo-svg {
      left: 20%;
    }
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width:768px) {
    .vo-svg {
      top: 43%;
      left: 30%;
    }
  }
  //                      __   _  ___ ____  _  _     __
  //                     | _| / |/ _ \___ \| || |   |_ |
  //    _____ _____ _____| |  | | | | |__) | || |_   | |_____ _____ _____
  //   |_____|_____|_____| |  | | |_| / __/|__   _|  | |_____|_____|_____|
  //                     | |  |_|\___/_____|  |_|    | |
  //                     |__|                       |__|
  @media (min-width: 1024px) and (min-height: 1366px){
    .vo-svg {
      top: 50%;
      left: 32%;
    }
  }
  //                      __   _ ____  ___   ___    __
  //                     | _| / |___ \( _ ) / _ \  |_ |
  //    _____ _____ _____| |  | | __) / _ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |/ __/ (_) | |_| |  | |_____|_____|_____|
  //                     | |  |_|_____\___/ \___/   | |
  //                     |__|                      |__|
  @media (min-width:1280px) {
    .vo-svg {
      top: 45%;
      left: 33%;
    }
  }
  //                      __   _ _____  __    __     __
  //                     | _| / |___ / / /_  / /_   |_ |
  //    _____ _____ _____| |  | | |_ \| '_ \| '_ \   | |_____ _____ _____
  //   |_____|_____|_____| |  | |___) | (_) | (_) |  | |_____|_____|_____|
  //                     | |  |_|____/ \___/ \___/   | |
  //                     |__|                       |__|
  @media (min-width: 1366px){
    .vo-bg{
      min-height: 75vh;
    }
    .vo-svg {
      top: 50%;
    }
  }
</style>
