<template>
  <section class="slide">
    <div class="slide-coll max1366w">
      <div class="slide-coll-content">
        <div>
          <h4>L’esprit de collaboration</h4>
        </div>
        <div class="slide-content-txt">
          <p id="pin-shadow-8">
            Fokus vous permet de créer autant de comptes utilisateurs que nécessaire. La gestion des accès par mots de
            passe fonctionne de manière classique. Chaque utilisateur gère ensuite ses états des lieux par le biais
            d’une liste ou d’un agenda interactif. Si besoin est, il pourra facilement réattribuer certaines visites
            à l’utilisateur de son choix.
          </p>
          <span class="shadow shadow-8">Collaboratif</span>
        </div>
      </div>
      <CollaborationAnimation></CollaborationAnimation>
    </div>
  </section>
</template>

<script>
import CollaborationAnimation from './animations/CollaborationAnimation'
export default {
  components: {
    CollaborationAnimation
  }
}
</script>
