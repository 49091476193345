<template>
  <section class="slide max1366w">
    <div class="slide-comb">
      <div class="slide-comb-content">
        <div class="slide-comb-content_title">
          <h4>Entrant, sortant... et vice-versa</h4>
        </div>
        <div class="slide-content-txt">
          <p id="pin-shadow-5">
            Etablir des états des lieux c’est aussi gérer une continuité, parfois très longue dans le temps. <br>
            Fokus simplifie au maximum cette gestion pour vous.
          </p>
          <p>
            Récupérez automatiquement un état des lieux sortant pour établir l’entrant, ou bien l’inverse, en
            choisissant spécifiquement les éléments que vous souhaitez reprendre. <span class="strong">Vous pouvez même <strong>établir
            simultanément un état des lieux sortant et entrant</strong></span>.
          </p>
          <span class="shadow shadow-5">Simultané</span>
        </div>
      </div>
      <CombinaisonAnimation></CombinaisonAnimation>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import CombinaisonAnimation from './animations/CombinaisonAnimation.vue'

export default {
  components: {
    CombinaisonAnimation
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/styles/_vars";
  @import "../assets/styles/_function";

  //                      __   _  ___ ____  _  _     __
  //                     | _| / |/ _ \___ \| || |   |_ |
  //    _____ _____ _____| |  | | | | |__) | || |_   | |_____ _____ _____
  //   |_____|_____|_____| |  | | |_| / __/|__   _|  | |_____|_____|_____|
  //                     | |  |_|\___/_____|  |_|    | |
  //                     |__|                       |__|
  @media (min-width: 1024px) {
    .slide-comb-content_title h4{
      width: 600px;
    }
  }
</style>
