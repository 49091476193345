import Vue from 'vue'
import App from './App.vue'
import KsVueScrollmagic from 'ks-vue-scrollmagic'
import Scrollspy, { Easing } from 'vue2-scrollspy'
import axios from 'axios'
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false
Vue.use(KsVueScrollmagic)
Vue.use(Scrollspy, {
  easing: Easing.Quadratic.In
})
Vue.use(axios)
Vue.use(VueLazyload)

Vue.mixin({
  data () {
    return {
      urlPrinc: 'https://fokus.immo'
    }
  },
  methods: {
    /**
     * Fonction permettant de creer une nouvelle scene ScrollMagic
     * return Scene ScrollMagic
     */
    createScene: function (trigElem, duration, tw) {
      return new this.$scrollmagic.Scene({
        triggerElement: trigElem,
        duration: duration
      })
        .setTween(tw)
    }
  }
})

new Vue({
  render: h => h(App)
}).$mount('#app')
