<template>
  <div id="app">
    <Header></Header>
    <div>
      <Dotnav></Dotnav>
      <main v-scroll-spy="{time: 1000, offset: 0}">
        <Intro></Intro>
        <Item1></Item1>
        <Item2></Item2>
        <Item3></Item3>
        <Item4></Item4>
        <Item5></Item5>
        <Item6></Item6>
        <Item7></Item7>
        <Item8></Item8>
        <Item9></Item9>
        <Item10></Item10>
        <div class="information-contact">
          <Info></Info>
          <Contact></Contact>
        </div>
      </main>
    </div>
    <footer>
      <span>Copyright 2018 - </span>
      <a href="http://logilink.fr" target="_blank"><img src="./assets/images/logilink-logo.svg" alt="logo logilink logiciel immobilier"></a>
      <a href="http://logilink.fr" target="_blank">logilink.fr</a>
    </footer>
  </div>
</template>

<script>
import Header from './components/Header'
import Intro from './components/Intro'
import Item1 from './components/Temps'
import Item2 from './components/Confort'
import Item3 from './components/Automatisme'
import Item4 from './components/Photo'
import Item5 from './components/Vocale'
import Item6 from './components/Combinaison'
import Item7 from './components/Document'
import Item8 from './components/Comparaison'
import Item9 from './components/Collaboratif'
import Item10 from './components/Integration'
import Dotnav from './components/Dotnav'
import Contact from './components/forms/Contact'

import Info from './components/Info'

export default {
  name: 'app',
  components: {
    Header,
    Intro,
    Item1,
    Item2,
    Item3,
    Item4,
    Item5,
    Item6,
    Item7,
    Item8,
    Item9,
    Item10,
    Info,
    Dotnav,
    Contact
  },
  mounted () {
    this.$nextTick(this.shadowTranslate)
  },
  methods: {
    shadowTranslate () {
      let vm = this.$ksvuescr.$gsap.TweenMax
      let nbrShadow = document.getElementsByClassName('shadow').length
      let animationMovement = {
        css: {
          transform: 'translateX(-1920px)',
          opacity: 0
        }
      }

      for (let i = 0; i < nbrShadow; i++) {
        let animateElem = document.getElementsByClassName('shadow-' + i)
        let tween = vm.to(animateElem, 1, animationMovement)
        let scene = this.createScene('#pin-shadow-' + i, 5000, tween)
        this.$ksvuescr.$emit('addScene', 'shadowSlide3', scene)
      }
    }
  },
  destroyed () {
    // Destroy ScrollMagic when our component is removed from DOM
    this.$ksvuescr.$emit('destroy')
  }

}
</script>

<style lang="scss">
  @import "assets/styles/_vars";
  @import "assets/styles/_function";
  @import "assets/styles/fonts.scss";
  @import "assets/styles/icons.scss";
  @import "assets/styles/normalize.scss";

  //     ____
  //    / ___|___  _ __ ___  _ __ ___  _   _ _ __
  //   | |   / _ \| '_ ` _ \| '_ ` _ \| | | | '_ \
  //   | |__| (_) | | | | | | | | | | | |_| | | | |
  //    \____\___/|_| |_| |_|_| |_| |_|\__,_|_| |_|
  //
  *[lazy=loading] {
    position: relative;
    &:before{
      content: " ";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 46px;
      height: 46px;
      margin: 1px;
      border-radius: 50%;
      border: 5px dashed black;
      border-color: black transparent black transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  html,body{
    overflow-x: hidden;
  }
  .strong{
    font-weight: bold;
  }
  p{
    font-family: $fontLight-1;
    line-height: 1.3;
    text-align: justify;
    font-size: 1em;
  }
  h3{
    padding: 15px 15px;
    font-family: $fontSemiBold-1;
    font-size: 2.3em;
    color: $color-primary-3;
    background: $color-secondary-2;
  }

  .slide{
    position: relative;
    min-height: 60vh;
    z-index: 5;
    h4{
      padding: 60px 15px 25px 15px;
      font-family: $fontSemiBold-1;
      font-size: 2em;
      margin: 0;
    }
    p{
      padding: 10px 15px;
      margin: 0;
    }
  }
  .slide-content-txt{
    position: relative;
  }
  .shadow{
    color: transparent;
    font-size: 4em;
    font-family: $fontSemiBold-1;
    display: block;
    width: 30px;
    user-select: none;
    position: absolute;
  }
  $positionXShadow: 115;
  $positionYShadow: 20;
  @each $number, $x, $y in
      (0,$positionXShadow,$positionYShadow),
      (1,$positionXShadow,10),
      (2,$positionXShadow,$positionYShadow),
      (3,$positionXShadow,18),
      (4,$positionXShadow,$positionYShadow),
      (5,$positionXShadow,$positionYShadow),
      (6,$positionXShadow,$positionYShadow),
      (7,$positionXShadow,$positionYShadow),
      (8,$positionXShadow,$positionYShadow),
      (9,$positionXShadow,24){
    .shadow-#{$number}{
      @include shadowLetter($x,$y);
    }
  }
  .shadow-5{
    z-index: 1;
  }

  footer{
    min-height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $fontLight-1;
    background: $color-secondary-1;
    color: $color-alpha;

    img{
      width: 85px;
      height: 20px;
    }

    a{
      color: $color-alpha-0;
      text-decoration: none;
    ;
    }
  }
  //                      __   _____  __    ___    __
  //                     | _| |___ / / /_  / _ \  |_ |
  //    _____ _____ _____| |    |_ \| '_ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |   ___) | (_) | |_| |  | |_____|_____|_____|
  //                     | |  |____/ \___/ \___/   | |
  //                     |__|                     |__|
  @media (min-width:360px) {
    .shadow-9{
      @include shadowLetter($positionXShadow,35);
    }
  }
  @media (min-width:360px) and (min-height: 740px){
    .shadow-9{
      @include shadowLetter($positionXShadow,50);
    }
  }
  //                      __   __________ ____    __
  //                     | _| |___ /___  | ___|  |_ |
  //    _____ _____ _____| |    |_ \  / /|___ \   | |_____ _____ _____
  //   |_____|_____|_____| |   ___) |/ /  ___) |  | |_____|_____|_____|
  //                     | |  |____//_/  |____/   | |
  //                     |__|                    |__|
  @media (min-width:375px) {
    .shadow-9{
      @include shadowLetter($positionXShadow,39);
    }
  }
  @media (min-width:375px) and (min-height: 812px){
    .shadow-9{
      @include shadowLetter($positionXShadow,60);
    }
  }
  //                      __   _  _   _ _  _     __
  //                     | _| | || | / | || |   |_ |
  //    _____ _____ _____| |  | || |_| | || |_   | |_____ _____ _____
  //   |_____|_____|_____| |  |__   _| |__   _|  | |_____|_____|_____|
  //                     | |     |_| |_|  |_|    | |
  //                     |__|                   |__|
  @media (min-width:414px) {
    .shadow-9{
      @include shadowLetter($positionXShadow,49);
    }
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width:768px) {
    h3{
      padding: 30px 15px;
      font-size: 3.8em;
      text-align: center;
    }
    p{
      font-size: 1.3em;
    }
    .slide {
      h4{
        padding: 50px 30px 10px 30px;
        font-size: 4em;
      }
      p{
        width: 600px;
        background: transparent;
        padding: 30px 30px;
      }
      p:first-child{
        padding-top: 60px;
      }
    }
    .slide-original-content{
      text-align: center;
      p{
        margin: 0 auto;
      }
    }
    $positionXShadow: 400;
    $positionYShadow: 25;
    @each $number, $x, $y in
        (0,$positionXShadow,$positionYShadow),
        (1,$positionXShadow,7),
        (2,$positionXShadow,$positionYShadow),
        (3,$positionXShadow,-14),
        (4,$positionXShadow,-11),
        (5,$positionXShadow,$positionYShadow),
        (6,$positionXShadow,$positionYShadow),
        (7,$positionXShadow,$positionYShadow),
        (8,$positionXShadow,$positionYShadow),
        (9,$positionXShadow,60){
      .shadow-#{$number}{
        @include shadowLetter($x,$y);
      }
    }
    .shadow{
      font-size: 6em;
    }
  }
  //                      __    ___   ___   ___    __
  //                     | _|  ( _ ) / _ \ / _ \  |_ |
  //    _____ _____ _____| |   / _ \| | | | | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | (_) | |_| | |_| |  | |_____|_____|_____|
  //                     | |   \___/ \___/ \___/   | |
  //                     |__|                     |__|
  @media (min-width:800px) {
    .shadow-9{
      @include shadowLetter(400,10);
    }
  }
  //                       __    ___ _____ _  _     __
  //                      | _|  ( _ )___ /| || |   |_ |
  //     _____ _____ _____| |   / _ \ |_ \| || |_   | |_____ _____ _____
  //    |_____|_____|_____| |  | (_) |__) |__   _|  | |_____|_____|_____|
  //                      | |   \___/____/   |_|    | |
  //                      |__|                     |__|
  @media (min-width:834px) {
    .shadow-9{
      @include shadowLetter($positionXShadow,-2);
    }
  }
  //                      __   _  ___ ____  _  _     __
  //                     | _| / |/ _ \___ \| || |   |_ |
  //    _____ _____ _____| |  | | | | |__) | || |_   | |_____ _____ _____
  //   |_____|_____|_____| |  | | |_| / __/|__   _|  | |_____|_____|_____|
  //                     | |  |_|\___/_____|  |_|    | |
  //                     |__|                       |__|
  @media (min-width: 1024px) {
    $positionXShadow: 700;
    $positionYShadow: 25;
    @each $number, $x, $y in
        (0,400,$positionYShadow),
        (1,$positionXShadow,8),
        (2,400,$positionYShadow),
        (3,$positionXShadow,-13),
        (4,$positionXShadow,-10),
        (5,$positionXShadow,$positionYShadow),
        (6,$positionXShadow,$positionYShadow),
        (7,$positionXShadow,$positionYShadow),
        (8,$positionXShadow,$positionYShadow),
        (9,$positionXShadow,-6){
      .shadow-#{$number}{
        @include shadowLetter($x,$y);
      }
    }
  }
  @media (min-width: 1024px) and (min-height: 1366px){
    .shadow-9{
      @include shadowLetter($positionXShadow,58);
    }
  }
  //                      __   _ ____  ___   ___    __
  //                     | _| / |___ \( _ ) / _ \  |_ |
  //    _____ _____ _____| |  | | __) / _ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |/ __/ (_) | |_| |  | |_____|_____|_____|
  //                     | |  |_|_____\___/ \___/   | |
  //                     |__|                      |__|
  @media (min-width:1280px) {
    .shadow-7 {
      text-shadow: -500px -300px #E6E6E6;
    }
    .max1366w{
      max-width: 1366px;
      margin: 0 auto;
    }
    .information-contact{
      display: grid;
      grid-template-areas: "a b";
      grid-auto-columns: 1fr 1fr;
    }
    .shadow-9{
      @include shadowLetter($positionXShadow,-3);
    }
  }
  //                      __   _ _____  __    __     __
  //                     | _| / |___ / / /_  / /_   |_ |
  //    _____ _____ _____| |  | | |_ \| '_ \| '_ \   | |_____ _____ _____
  //   |_____|_____|_____| |  | |___) | (_) | (_) |  | |_____|_____|_____|
  //                     | |  |_|____/ \___/ \___/   | |
  //                     |__|                       |__|
  @media (min-width: 1366px){
    .shadow-9{
      @include shadowLetter($positionXShadow,-7);
    }
  }//                      __   _ _  _   _  _    ___    __
  //                     | _| / | || | | || |  / _ \  |_ |
  //    _____ _____ _____| |  | | || |_| || |_| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |__   _|__   _| |_| |  | |_____|_____|_____|
  //                     | |  |_|  |_|    |_|  \___/   | |
  //                     |__|                         |__|
  @media (min-width: 1440px){
    .shadow-9{
      @include shadowLetter($positionXShadow,-1);
    }
  }
  //                      __   _  ___ ____   ___    __
  //                     | _| / |/ _ \___ \ / _ \  |_ |
  //    _____ _____ _____| |  | | (_) |__) | | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |\__, / __/| |_| |  | |_____|_____|_____|
  //                     | |  |_|  /_/_____|\___/   | |
  //                     |__|                      |__|
  @media (min-width: 1920px){
    .title img {
      margin-top: 10%;
    }
    .shadow-9{
      @include shadowLetter($positionXShadow,3);
    }
  }

</style>
