<template>
  <section class="slide">
    <div class="slide-doc">
      <div class="slide-original-content slide-doc-content max1366w">
        <div>
          <h4>Documents en bonne et due forme</h4>
        </div>
        <div class="slide-content-txt">
          <p id="pin-shadow-6">
            La <strong>conformité de l’état des lieux</strong> généré en fin de visite est garantie par l’ajout des signatures.
            Mais vous pouvez aussi demander au locataire de <strong>parapher chacune des pages</strong>.
          </p>
          <p>
            Le document lui sera automatiquement expédié par email. Un rapport au format PDF, juridiquement
            fiable, clair et plaisant à consulter.
          </p>
          <span class="shadow shadow-6">Documents</span>
        </div>
      </div>
      <div class="doc-container">
        <div class="docBg">
          <div v-lazy:background-image="this.urlPrinc + imgUrl" class="doc-bg"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      imgUrl: '/img/papier.png'
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/styles/_vars";
  @import "../assets/styles/_function";

  .slide-doc{
    margin-top: 45px;
    padding-bottom: 45px;
    background: $color-primary-2;
  }
  .docBg{
    position: relative;
    z-index: 1;
  }
  .doc-bg{
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 60vh;
  }
  .slide-doc-content{
    padding: 10px 0 50px 0;
  }
</style>
