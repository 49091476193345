<template>
  <div class="t-container">
    <span class="twotoone-trigger"></span>
    <div class="twotoone">
      <div class="t-bg" v-lazy:background-image="this.urlPrinc + imgUrl1" id="t-obj1"></div>
      <div class="t-bg" v-lazy:background-image="this.urlPrinc + imgUrl2" id="t-obj2"></div>
      <div class="t-bg" v-lazy:background-image="this.urlPrinc + imgUrlf" id="t-objfinal"></div>
      <div class="t-bg" v-lazy:background-image="this.urlPrinc + imgUrlinit" id="t-init"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgUrl1: '/img/t-obj1.png',
      imgUrl2: '/img/t-obj2.png',
      imgUrlf: '/img/t-objfinal.png',
      imgUrlinit: '/img/t-init.png'
    }
  },
  mounted () {
    this.$nextTick(this.combinaisonAnimation)
  },
  methods: {
    combinaisonAnimation () {
      let trig = '.twotoone-trigger'
      let vm = this.$ksvuescr.$gsap.TweenMax

      let m1024 = window.matchMedia('(min-width: 1024px) and (max-height: 768px)')
      m1024.addListener(f1024)
      let m1366 = window.matchMedia('(min-width: 1366px)')
      m1366.addListener(f1366)

      let anime1 = document.getElementById('t-obj1')
      let anime2 = document.getElementById('t-obj2')
      let animefinal = document.getElementById('t-objfinal')

      function ecouteur (listenerMedia, vm, animateElem, x, etat1, y, etat2, functionPrec) {
        let tween = null
        if (listenerMedia.matches) {
          tween = vm.to(animateElem, 1, { backgroundPosition: x + etat1 + ' ' + y + etat2 })
        } else {
          if (typeof functionPrec === 'function') {
            return functionPrec(vm, animateElem)
          }
        }
        return tween
      }

      function init (vm, animateElem) {
        return vm.to(animateElem, 1, { backgroundPosition: '50% 0px' })
      }

      function f1024 (vm, animateElem) {
        return ecouteur(m1024, vm, animateElem, 0, 'px', 0, 'px', init)
      }
      function f1366 (vm, animateElem) {
        return ecouteur(m1366, vm, animateElem, 50, '%', 0, 'px', f1024)
      }

      function fAnime (vm, animateElem) {
        return f1366(vm, animateElem)
      }

      let tw1 = fAnime(vm, anime1)
      let tw1prim = vm.to(anime1, 1, { opacity: 0, delay: 1 })
      let tw2 = fAnime(vm, anime2)
      let tw2prim = vm.to(anime2, 1, { opacity: 0, delay: 1 })

      let twfinal = vm.to(animefinal, 1, { opacity: 1, delay: 1.5 })

      // create scene and set its params
      const scene = this.createScene(trig, 500, tw1)
      const scene1prim = this.createScene(trig, 500, tw1prim)
      const scene2 = this.createScene(trig, 500, tw2)
      const scene2prim = this.createScene(trig, 500, tw2prim)
      const scenefinal = this.createScene(trig, 500, twfinal)

      // Add scene to ScrollMagic controller by emiting an 'addScene' event on vm.$ksvuescr (which is our global event bus)
      this.$ksvuescr.$emit('addScene', 'combinaisonAnimation', scene)
      this.$ksvuescr.$emit('addScene', 'combinaisonAnimation', scene1prim)
      this.$ksvuescr.$emit('addScene', 'combinaisonAnimation', scene2)
      this.$ksvuescr.$emit('addScene', 'combinaisonAnimation', scene2prim)
      this.$ksvuescr.$emit('addScene', 'combinaisonAnimation', scenefinal)
    }
  },
  destroyed () {
    // Destroy ScrollMagic when our component is removed from DOM
    this.$ksvuescr.$emit('destroy')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../assets/styles/function';
  .twotoone{
    position: relative;
    min-height: 100vh;
  }
  .t-bg{
    width: 100%;
    min-height: 100vh;
    position: absolute;
  }
  #t-init{
    @include bgCoverSubFolder('t-init','png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
  }
  #t-objfinal{
    @include bgCoverSubFolder('t-objfinal','png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 4;
    opacity: 0;
  }
  #t-obj1{
    @include bgCoverSubFolder('t-obj1','png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% -220px;
    z-index: 2;
  }
  #t-obj2{
    @include bgCoverSubFolder('t-obj2','png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 85% -220px;
    z-index: 3;
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width:768px) {
    #t-obj1{
      background-position: 50% -400px;
    }
    #t-obj2{
      background-position: 85% -400px;
    }
  }
  //                      __   _  ___ ____  _  _     __
  //                     | _| / |/ _ \___ \| || |   |_ |
  //    _____ _____ _____| |  | | | | |__) | || |_   | |_____ _____ _____
  //   |_____|_____|_____| |  | | |_| / __/|__   _|  | |_____|_____|_____|
  //                     | |  |_|\___/_____|  |_|    | |
  //                     |__|                       |__|
  @media (min-width: 1024px) and (max-height: 768px){
    #t-obj1{
      background-position: 0px -350px;
    }
    #t-obj2{
      background-position: -230px -350px;
    }
  }
  //                      __   _ ____  ___   ___    __
  //                     | _| / |___ \( _ ) / _ \  |_ |
  //    _____ _____ _____| |  | | __) / _ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |/ __/ (_) | |_| |  | |_____|_____|_____|
  //                     | |  |_|_____\___/ \___/   | |
  //                     |__|                      |__|
  @media (min-width: 1280px){
    #t-obj1{
      background-position: 0px -350px;
    }
    #t-obj2{
      background-position: -230px -350px;
    }
    #t-init, #t-objfinal, #t-obj1, #t-obj2{
      background-size: contain;
    }
  }
  //                      __   _ _____  __    __     __
  //                     | _| / |___ / / /_  / /_   |_ |
  //    _____ _____ _____| |  | | |_ \| '_ \| '_ \   | |_____ _____ _____
  //   |_____|_____|_____| |  | |___) | (_) | (_) |  | |_____|_____|_____|
  //                     | |  |_|____/ \___/ \___/   | |
  //                     |__|                       |__|
  @media (min-width: 1366px){
    #t-obj1{
      background-position: 0% -350px;
    }
    #t-obj2{
      background-position: -60% -350px;
    }
  }
  //                      __   _  __    ___   ___    __
  //                     | _| / |/ /_  / _ \ / _ \  |_ |
  //    _____ _____ _____| |  | | '_ \| | | | | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | | (_) | |_| | |_| |  | |_____|_____|_____|
  //                     | |  |_|\___/ \___/ \___/   | |
  //                     |__|                       |__|
  @media (min-width: 1600px){
    #t-obj2{
      background-position: -165% -350px;
    }
  }
  //                      __   _  ___ ____   ___    __
  //                     | _| / |/ _ \___ \ / _ \  |_ |
  //    _____ _____ _____| |  | | (_) |__) | | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |\__, / __/| |_| |  | |_____|_____|_____|
  //                     | |  |_|  /_/_____|\___/   | |
  //                     |__|                      |__|
  @media (min-width: 1920px){
    #t-obj2{
      background-position: -200% -350px;
    }
  }
</style>
