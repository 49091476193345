<template>
  <div class="s-container">
    <span class="s-trigger"></span>
    <div class="separatorAnimation">
      <div class="s-bg" v-lazy:background-image="this.urlPrinc + imgUrltop" id="s-top"></div>
      <div class="s-bg" v-lazy:background-image="this.urlPrinc + imgUrlinit" id="s-init"></div>
      <div class="s-bg" v-lazy:background-image="this.urlPrinc + imgUrlbot" id="s-bot"></div>
      <div class="s-resultat">
        <div class="s-svg">
          <Fleche></Fleche>
        </div>
        <div v-lazy:background-image="this.urlPrinc + imgUrlfeuille" class="s-feuille"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Fleche from './Fleche'
export default {
  name: 'SeparatorAnimation',
  data () {
    return {
      imgUrltop: '/img/s-top.png',
      imgUrlinit: '/img/s-init.png',
      imgUrlbot: '/img/s-bot.png',
      imgUrlfeuille: '/img/feuille.png'
    }
  },
  components: {
    Fleche
  },
  mounted () {
    this.$nextTick(this.separatorAnimation)
  },
  methods: {
    separatorAnimation () {
      let m768 = window.matchMedia('(min-width: 768px)')
      m768.addListener(f768)

      let trig = '.s-trigger'
      let vm = this.$ksvuescr.$gsap.TweenMax

      let anime1 = document.getElementById('s-top')
      let anime2 = document.getElementById('s-bot')

      function ecouteur (listenerMedia, vm, animateElem, etat, x, y, functionPrec) {
        let tween = null
        if (listenerMedia.matches) {
          tween = vm.to(animateElem, 0.1, { backgroundPosition: etat + x + 'px ' + etat + y + 'px' }, 0)
        } else {
          if (typeof functionPrec === 'function') {
            return functionPrec(vm, animateElem, etat)
          }
        }
        return tween
      }

      function init (vm, animateElem, etat) {
        if (etat === '-') {
          return vm.to(animateElem, 1, { backgroundPosition: '-100px -100px' })
        } else {
          return vm.to(animateElem, 1, { backgroundPosition: '100px 100px' })
        }
      }
      function f768 (vm, animateElem, etat) {
        return ecouteur(m768, vm, animateElem, etat, 150, 150, init)
      }
      function fAnime (vm, animateElem, etat) {
        return f768(vm, animateElem, etat)
      }

      let tw1 = fAnime(vm, anime1, '-')
      let tw2 = fAnime(vm, anime2, '')

      // create scene and set its params
      const scene = this.createScene(trig, 350, tw1)
      const scene2 = this.createScene(trig, 350, tw2)

      // Add scene to ScrollMagic controller by emiting an 'addScene' event on vm.$ksvuescr (which is our global event bus)
      this.$ksvuescr.$emit('addScene', 'separatorAnimation', scene)
      this.$ksvuescr.$emit('addScene', 'separatorAnimation', scene2)
    }
  },
  destroyed () {
    // Destroy ScrollMagic when our component is removed from DOM
    this.$ksvuescr.$emit('destroy')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../assets/styles/function';

  .separatorAnimation{
    position: relative;
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
  }
  .s-bg{
    position: absolute;
    width: 100%;
    min-height: 100vh;
  }
  #s-top{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 0;
    z-index: 3;
  }
  #s-init{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 0;
    z-index: 2;
  }
  #s-bot{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 0;
    z-index: 1;
  }
  .s-resultat {
    position: absolute;
    top: 45%;
    left: 20%;
    width: 100%;
  }
  .s-svg{
    transform: rotate(-35deg);
  }
  .s-feuille{
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 70vh;
    width: 100%;
    margin-left: -15%;
  }
  //                      __   _____  __    ___    __
  //                     | _| |___ / / /_  / _ \  |_ |
  //    _____ _____ _____| |    |_ \| '_ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |   ___) | (_) | |_| |  | |_____|_____|_____|
  //                     | |  |____/ \___/ \___/   | |
  //                     |__|                     |__|
  @media (min-width:360px) {
    .separatorAnimation{
      min-height: 80vh;
    }
    .s-resultat {
      position: absolute;
      top: 55%;
      left: 20%;
      width: 100%;
    }
  }
  @media (min-width:360px) and (min-height: 740px){
    .separatorAnimation{
      min-height: 70vh;
    }
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width: 768px) {
    .separatorAnimation{
      min-height: 95vh;
    }
    .s-resultat {
      top: 60%;
    }
    .s-feuille{
      margin-top: 20%;
    }
  }
  //                      __   _  ___ ____  _  _     __
  //                     | _| / |/ _ \___ \| || |   |_ |
  //    _____ _____ _____| |  | | | | |__) | || |_   | |_____ _____ _____
  //   |_____|_____|_____| |  | | |_| / __/|__   _|  | |_____|_____|_____|
  //                     | |  |_|\___/_____|  |_|    | |
  //                     |__|                       |__|
  @media (min-width: 1024px) {
    .s-resultat {
      top: 50%;
      left: 10%;
    }
  }
  @media (min-width: 1024px) and (min-height: 1366px){
    .s-resultat {
      top: 60%;
      left: 20%;
    }
  }
  //                      __   _ ____  ___   ___    __
  //                     | _| / |___ \( _ ) / _ \  |_ |
  //    _____ _____ _____| |  | | __) / _ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |/ __/ (_) | |_| |  | |_____|_____|_____|
  //                     | |  |_|_____\___/ \___/   | |
  //                     |__|                      |__|
  @media (min-width: 1280px) {
    .s-resultat {
      top: 25%;
      left: 50%;
    }
    .s-svg{
      transform: rotate(265deg);
      width: 60px;
    }
    .s-feuille{
      margin-top: -12%;
      margin-left: -20%;
    }
  }
</style>
