<template>
  <section class="slide nav-trigger" v-lazy:background-image="this.urlPrinc + imgUrl" id="bg-temps">
    <div class="slide-tm max1366w">
      <div class="slide-tm-content">
        <div>
          <h4>Gain de temps</h4>
        </div>
        <div class="slide-content-txt">
          <p id="pin-shadow-0">
            Chaque détail de Fokus a été pensé pour apporter des réponses concrètes aux contraintes de votre métier.
            Listes préformatées, saisie réactive, envoi instantané au locataire, sauvegarde automatique sur un serveur
            sécurisé, édition de comparatifs… <br>
            <span class="strong">Fokus réduit allègrement votre charge de travail avant, pendant et après chaque visite</span>.
          </p>
          <span class="shadow shadow-0">Temps</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      imgUrl: '/img/interface.png'
    }
  },
  mounted () {
    this.$nextTick(this.backgroundPin)
  },
  methods: {
    /**
     * Fonction fixant le background du slide simple INTERFACE
     */
    backgroundPin () {
      let animateElem = document.getElementById('bg-temps')
      let tween = this.$ksvuescr.$gsap.TweenMax.to(animateElem, 0.1, { backgroundPositionX: '-=900', backgroundPositionY: '250px' })
      const scene = this.createScene('.slide-tm', 900, tween)
      this.$ksvuescr.$emit('addScene', 'backgroundPin', scene)
    }
  },
  destroyed () {
    // Destroy ScrollMagic when our component is removed from DOM
    this.$ksvuescr.$emit('destroy')
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/styles/_vars";
  @import "../assets/styles/_function";

  #bg-temps{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position-x: 200px;
    background-position-y: 200px;
    min-height: 120vh;
  }
  .slide-tm-content{
    background: transparentize($color-alpha, 0.2);
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width: 768px) {
    .slide-tm-content{
      background: transparent;
    }
    #bg-temps{
      min-height: 90vh;
      background-position-x: 600px;
    }
  }
</style>
