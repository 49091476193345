<template>
  <div class="dotnav">
    <ul v-scroll-spy-active="{class: 'customActive'}" v-scroll-spy-link>
      <li :key="item" v-for="item in sections" class="menu-item">
        <a :href="item.anchor">
          <span class="dotnav-title">{{item.title}}</span>
          <span class="icon-round"></span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      sections: [
        {
          title: 'Fokus',
          anchor: '#fokus-introduction'
        },
        {
          title: 'Interface',
          anchor: '#interface-logiciel'
        },
        {
          title: 'Utilisation',
          anchor: '#utilisation-logiciel'
        },
        {
          title: 'Saisie données',
          anchor: '#saisie-donnees'
        },
        {
          title: 'Prise de photos',
          anchor: '#photos'
        },
        {
          title: 'Saisie vocale',
          anchor: '#vocale'
        },
        {
          title: 'Etablir EDL',
          anchor: '#etat-des-lieux'
        },
        {
          title: 'Documents',
          anchor: '#documents'
        },
        {
          title: 'Gestion EDL',
          anchor: '#gestion-edl'
        },
        {
          title: 'Collaboratif',
          anchor: '#collaboratif'
        },
        {
          title: 'Contact',
          anchor: '#contact'
        }
      ]
    }
  },
  mounted () {
    this.$nextTick(this.navigationAnimation)
  },
  methods: {
    navigationAnimation () {
      let trig = '.nav-trigger'

      // create scene and set its params
      const scene = new this.$scrollmagic.Scene({
        triggerElement: trig
      })
        .setPin('.dotnav')

      this.$ksvuescr.$emit('addScene', 'navigationAnimation', scene)
    }
  },
  destroyed () {
    this.$ksvuescr.$emit('destroy')
  }
}
</script>

<style lang="scss">
  @import "../assets/styles/_vars";
  .dotnav{
    display: none;
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width:768px) {
    .dotnav{
      position: absolute;
      display: flex;
      flex-direction: column;
      right: 2%;
      margin-top: 15px;
      z-index: 50;
      font-family: $fontSemiBold-1;
    }
    .dotnav-title{
      width: 150px;
      text-align: right;
      margin-right: 10px;
      opacity: 0;
      transition: all 0.2s linear;
    }
    .dotnav a{
      text-decoration: none;
      color: transparentize($color-beta,0.5);
      transition: padding-right 0.3s linear;
      width: 150px;
      display: flex;
    }
    .dotnav li{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      cursor: pointer;
      transition: opacity 0.2s linear;

      &:hover{
        opacity: 1;
      }
      &:hover > a > .dotnav-title{
        opacity: 1;
      }
    }
    .dotnav li:not(:last-child){
      margin-bottom: 10px;
    }

    .customActive {
      a{
        color: $color-dotnav;
        padding-right: 5px;
        transition: opacity 0.5s;
      }
      a > [class^='icon-round']{
        border-radius: 50%;
        background: $color-primary-2;
        color: $color-alpha;
      }
//      a > .dotnav-title {
//        text-shadow: -1px 0 $color-alpha, 0 1px $color-alpha, 1px 0 $color-alpha, 0 -1px $color-alpha
//      }
    }
  }
  //                      __   _ ____  ___   ___    __
  //                     | _| / |___ \( _ ) / _ \  |_ |
  //    _____ _____ _____| |  | | __) / _ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |/ __/ (_) | |_| |  | |_____|_____|_____|
  //                     | |  |_|_____\___/ \___/   | |
  //                     |__|                      |__|
  @media (min-width:1280px) {
    .customActive {
      & > a > .dotnav-title{
        opacity: 1;
      }
    }
  }
</style>
