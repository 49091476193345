<template>
  <section class="slide">
    <div class="slide-pho">
      <div class="slide-original-content slide-pho-content">
        <div class="max1366w">
          <h4>Photo à l'appui</h4>
        </div>
        <div class="slide-content-txt max1366w">
          <p id="pin-shadow-3">
            Couper court aux éventuelles protestations en constatant clairement les choses est une obligation.
            Dans ce cas, une image vaut parfois tous les discours… Fokus vous permet d’insérer dans vos états des
            lieux <strong>autant de photos</strong> que vous le souhaitez.
          </p>
          <p>
            En un clic, ajoutez, supprimez ou légendez vos images. Et annotez-les en couleurs, grâce à l’outil de
            dessin spécialement intégré.
          </p>
          <span class="shadow shadow-3">Photos</span>
        </div>
      </div>
      <PhotoAnimation></PhotoAnimation>
    </div>
  </section>
</template>

<script>
import PhotoAnimation from './animations/PhotoAnimation.vue'

export default {
  components: {
    PhotoAnimation
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/styles/_vars";
  @import "../assets/styles/_function";

  .slide-pho-content{
    background: $color-primary-2;
    padding: 0 0 80px 0;
  }
</style>
