<template>
  <section class="info" id="contact">
    <h2>Nous contacter</h2>
    <div class="info-contact">
      <div class="info-contact-addr">
        <span class="icon-pointer"></span>
        <div class="info-contact-addr_txt">
          <p>Logilink - Editeur de logiciel immobilier</p>
          <p>17, rue de la République, <br> 13002 Marseille</p>
        </div>
      </div>
      <div class="info-contact-phone">
        <span class="icon-phone"></span><p>04 91 99 44 43</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Info'
}
</script>

<style lang="scss">
  @import "../assets/styles/_vars";
  @import "../assets/styles/_function";

  .info{
    @include bgCoverLinearGradient('contact', 'jpg', bottom, $color-primary-2,0.1,$color-primary-2,0.1);
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2{
      font-family: $fontLight-1;
      font-size: 2.5em;
      color: $color-primary-1;
      margin-bottom: 50px;
      font-weight: bold;
      background: transparent;
      text-align: center;
    }
  }
  .info-contact{
    font-weight: bold;
    color: $color-primary-3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    & > div{
      @extend %flexAllCenter;

      & > span:first-child{
        margin-right: 25px;
        font-weight: bold;
      }
    }
    .info-contact-phone{
      margin-top: 25px;
    }
    a{
      text-decoration: none;
      color: $color-primary-3;
      font-family: $fontLight-1;
    }
  }
  .info-contact > .info-contact-addr{
    align-items: flex-start;

    & > span:first-child{
      margin-top: 85px;
    }
  }
  .info-contact-addr_txt{
    flex-direction: column;
    width: 200px;
    p:first-child{
      margin-bottom: 15px;
    }
    p{
      text-align: left;
      font-size: 100%;
    }
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width: 768px) {
    .info-contact p{
      font-size: 1.6em;
    }
    .info h2{
      font-size: 5.5em;
    }
    .info-contact-addr_txt{
      width: auto;
    }
    .info-contact > div > span:first-child {
      font-size: 25px;
    }
    .info-contact > .info-contact-addr > span:first-child {
      margin-top: 105px;
    }
  }
  //                      __   _ ____  ___   ___    __
  //                     | _| / |___ \( _ ) / _ \  |_ |
  //    _____ _____ _____| |  | | __) / _ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |/ __/ (_) | |_| |  | |_____|_____|_____|
  //                     | |  |_|_____\___/ \___/   | |
  //                     |__|                      |__|
  @media (min-width:1280px) {
    .info-contact p{
      font-size: 1.3em;
    }
    .info h2{
      font-size: 4.5em;
    }
    .info{
      justify-content: flex-start;
      padding-top: 115px;
    }
    .info-contact > .info-contact-addr > span:first-child {
      margin-top: 85px;
    }
  }
</style>
