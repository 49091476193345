<template>
  <section class="slide">
    <div class="slide-conf">
      <div class="slide-original-content slide-conf-content max1366w">
        <div>
          <h4>Liberté d'action</h4>
        </div>
        <div class="slide-content-txt">
          <p id="pin-shadow-1">
            Mode portrait ou paysage, à vous de choisir. Fokus intègre les meilleures technologies sans rigidité.
            Son interface intuitive et son ergonomie ont été soigneusement étudiées pour vous apporter
            le maximum de fluidité et d’assistance.
          </p>
          <p>
            Fokus n’a pas besoin de réseau pour <strong>réaliser un état des lieux</strong>.
            Seul l'import-export des données utilise une connexion internet.
          </p>
        </div>
        <span class="shadow shadow-1">Utilisation</span>
      </div>
      <div class="conf-container">
        <div class="confBg">
          <div v-lazy:background-image="this.urlPrinc + imgUrl" class="conf-bg"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      imgUrl: '/img/utilisation.png'
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/styles/_vars";
  @import "../assets/styles/_function";

  .slide-conf-content{
    padding: 50px 0 71px 0;
  }
  .confBg{
    position: relative;
  }
  .conf-bg{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 80vh;
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width:768px) {
    .slide-conf-content{
      padding: 50px 0 100px 0;
    }
  }
</style>
