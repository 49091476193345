<template>
  <section class="slide">
    <div class="slide-voc">
      <div class="slide-original-content slide-voc-content">
        <div class="max1366w">
          <h4>La voix de son maître</h4>
        </div>
        <div class="slide-content-txt max1366w">
          <p id="pin-shadow-4">
            Toujours dans l'optique de gagner du temps et de vous offrir un confort d’utilisation optimal,
            <strong>Fokus intègre la dictée vocale</strong>. Il se charge de transcrire fidèlement vos paroles en texte écrit
            dans les champs de commentaires.
          </p>
          <span class="shadow shadow-4">Vocale</span>
        </div>
      </div>
      <VocaleAnimation></VocaleAnimation>
    </div>
  </section>
</template>

<script>
import VocaleAnimation from './animations/VocaleAnimation'
export default {
  components: {
    VocaleAnimation
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/styles/_vars";
  @import "../assets/styles/_function";

  .slide-voc-content{
    background: $color-primary-1;
    padding: 0 0 82px 0;
  }
</style>
