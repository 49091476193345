<template>
  <div class="contact-form">
    <form class="vue-form" @submit.prevent="submit">
      <div id="responseSend" class="responseCheck">Votre message a été envoyé.</div>
      <div id="responseFail" class="responseCheck">L'envoie du message a échoué.</div>
      <div>
        <label for="name" v-on:click="focusLabel">Nom</label>
        <input type="text" name="name" id="name" required="" v-model="name.value" v-on:click="focusInput" v-on:focus="focusInput">
        <span class="error-icon" v-if="checkInputName"><span class="icon-valid"></span></span>
        <span class="error-icon" v-else><span class="icon-error"></span></span>
      </div>
      <div>
        <label for="societe">Société</label>
        <input type="text" name="societe" id="societe" v-model="societe.value" v-on:click="focusInput" v-on:focus="focusInput">
        <span class="error-icon" v-if="checkInputSociete"><span class="icon-valid"></span></span>
        <span class="error-icon" v-else><span class="icon-error"></span></span>
      </div>
      <div>
        <label for="email">Email</label>
        <input type="email" name="email" id="email" required=""
               :class="{ email , error: !email.valid }"
               v-model="email.value" v-on:click="focusInput" v-on:focus="focusInput">
        <span class="error-icon" v-if="checkInputMail"><span class="icon-valid"></span></span>
        <span class="error-icon" v-else><span class="icon-error"></span></span>
      </div>
      <div>
        <label class="label-textarea" for="textarea">Message</label>
        <textarea class="message" name="textarea" id="textarea" required=""
                  v-model="message.text" rows="5" v-on:click="focusInput" v-on:focus="focusInput"></textarea>
        <span class="error-icon" v-if="checkInputMessage"><span class="icon-valid"></span></span>
        <span class="error-icon" v-else><span class="icon-error"></span></span>
      </div>
      <div>
        <input type="submit" value="Envoyer">
      </div>
    </form>
  </div>
</template>

<script>
const axios = require('axios')
const formData = new FormData()

export default {
  data () {
    return {
      name: {
        value: '',
        valid: false
      },
      societe: {
        value: '',
        valid: true
      },
      email: {
        value: '',
        valid: false
      },
      message: {
        text: '',
        valid: false
      },
      submitted: false,
      responseCheck: false
    }
  },
  /* eslint-disable */
  computed: {
    checkInputMessage: function () {
      if(/^[a-zA-Z0-9.,:!?'"\-()@çàéè€\s]*$/.test(this.message.text) !== true || this.message.text.length < 5){
        this.message.valid = false
        return false;
      }else{
        this.message.valid = true
        return true;
      }
    },
    checkInputSociete: function () {
      if(/^[a-zA-Z0-9.,-\s]*$/.test(this.societe.value) !== true){
        this.societe.valid = false
        return false;
      }else{
        this.societe.valid = true;
        return true;
      }
    },
    checkInputName: function () {
      if(/^[a-zA-Z\s]*$/.test(this.name.value) !== true || this.name.value.length <= 1){
        this.name.valid = false
        return false;
      }else{
        this.name.valid = true
        return true;
      }
    },
    checkInputMail: function () {
      if(this.checkmail(this.email.value) !== true){
        this.email.valid = false
        return false;
      }else{
        this.email.valid = true
        return true;
      }
    }
  },
  methods: {
    checkmail: function (value) {
      let emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return emailRegExp.test(value)
    },
    focusLabel: function (e) {
      console.log(e.srcElement.style.transform = 'translateX(0)')
    },
    focusInput: function (e) {
      e.srcElement.parentElement.children[0].style.transform = 'translateX(0)'
      e.srcElement.nextElementSibling.children[0].style.opacity = '1'
    },
    checkForm: function () {
      console.log(this.name.valid)
      console.log(this.societe.valid)
      console.log(this.email.valid)
      console.log(this.message.valid)
      if(this.name.valid && this.societe.valid && this.email.valid && this.message.valid){
        return true
      }else{
        return false
      }
    },
    submit: function () {
      function tog(bloc, non) {
        document.getElementById(bloc).style.display = 'block'
        document.getElementById(non).style.display = 'none'
        setTimeout(function () {
          document.getElementById(bloc).style.display = 'none'
        }, 10000)
      }
      this.submitted = true
      if(this.checkForm()){
        formData.append('name', this.name.value)
        formData.append('societe', this.societe.value)
        formData.append('email', this.email.value)
        formData.append('message', this.message.text)
        axios.post('/contact.php', formData)
          .then(response => {
            // console.log(response)
            this.responseCheck = true
            tog('responseSend', 'responseFail')
            this.name.value = ''
            this.societe.value = ''
            this.email.value = ''
            this.message.text = ''
          })
          .catch(error => {
            // console.log(error.response)
            this.responseCheck = false
            tog('responseFail', 'responseSend')
          })
      }else{
        this.responseCheck = false
        tog('responseFail', 'responseSend')
      }
    }
  }
}
</script>

<style lang="scss">
  @import "../../assets/styles/_vars";
  .contact-form{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    background: $color-primary-2;
  }
  .vue-form label{
    font-family: $fontLight-1;
    color: $color-primary-1;
    font-weight: bold;
    transform: translateY(25px);
    transition: transform 0.2s linear;
    z-index: 3;
  }
  .vue-form .label-textarea{
    transform: translateY(85px);
  }
  .vue-form div {
    display: flex;
    flex-direction: column;
    z-index: 2;

    & > .error-icon{
      text-align: right;
      transform: translateY(-30px);
      z-index: 0;

      & > .icon-error{
        color: red;
      }

      & > .icon-valid{
        color: green;
      }
    }
  }
  .vue-form > div:not(:last-child){
    margin-bottom: 15px;
  }
  .vue-form input[type='text'], .vue-form input[type='email'], .vue-form textarea{
    background: transparent;
    border: none;
    border-bottom: 1px solid $color-primary-1;
    min-width: 200px;
    padding: 10px 0;
    font-family: $fontLight-1;
    z-index: 4;

    &:focus{
      outline: none;
    }

    & + span > span{
      opacity: 0;
    }
  }
  .vue-form input[type='submit']{
    float:right;
    padding:8px 12px;
    margin:8px 0 0;
    border:2px solid #78788c;
    background: transparent;
    color:#5a5a6e;
    cursor:pointer;
    transition:all .3s;

    &:hover{
      background:#78788c;
      color:#fff
    }
  }
  .vue-form #responseSend{
    display: none;
  }
  .vue-form #responseFail{
    display: none;
  }
  .vue-form > .responseCheck{
    font-family: $fontLight-1;
    padding: 15px 10px;
    margin-bottom: 55px;
    background: $color-primary-1;
    color: $color-alpha;
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width:768px) {
    .vue-form input[type='text'], .vue-form input[type='email'], .vue-form textarea{
      min-width: 510px;
    }
  }
  //                      __   _ ____  ___   ___    __
  //                     | _| / |___ \( _ ) / _ \  |_ |
  //    _____ _____ _____| |  | | __) / _ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |/ __/ (_) | |_| |  | |_____|_____|_____|
  //                     | |  |_|_____\___/ \___/   | |
  //                     |__|                      |__|
  @media (min-width:1280px) {
    .vue-form input[type='text'], .vue-form input[type='email'], .vue-form textarea{
      min-width: 400px;
    }
    .contact-form{
      background: $color-alpha;
    }
    .vue-form{
      margin-left: -15%;
    }
  }
</style>
