<template>
  <section class="slide max1366w">
    <div class="slide-comp">
      <div class="slide-comp-content">
        <div>
          <h4 class="h4-comparatif">Stockage et gestion</h4>
        </div>
        <div class="slide-content-txt slide-comp-content_txt">
          <p id="pin-shadow-7">
            Vos états des lieux sont stockés sur un serveur sécurisé, accessibles par un moteur de recherche. <br>
            En un clic, vous pouvez également éditer un <strong>état des lieux comparatif</strong>. Ce document provenant de la fusion
            des deux derniers états des lieux (entrant et sortant), vous permet d’évaluer en un coup d’œil, les
            éventuelles mesures à prendre après le départ d’un locataire.
          </p>
          <span class="shadow shadow-7">Comparatifs</span>
        </div>
      </div>
      <ComparaisonAnimation></ComparaisonAnimation>
    </div>
  </section>
</template>

<script>
import ComparaisonAnimation from './animations/ComparaisonAnimation.vue'

export default {
  components: {
    ComparaisonAnimation
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/styles/_vars";
  @import "../assets/styles/_function";
  .slide-comp{
    min-height: 215vh;
  }
  //                      __   _____  __    ___    __
  //                     | _| |___ / / /_  / _ \  |_ |
  //    _____ _____ _____| |    |_ \| '_ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |   ___) | (_) | |_| |  | |_____|_____|_____|
  //                     | |  |____/ \___/ \___/   | |
  //                     |__|                     |__|
  @media (min-width:360px) {
    .slide-comp{
      min-height: 185vh;
    }
  }
  @media (min-width:360px) and (min-height:740px){
    .slide-comp{
      min-height: 165vh;
    }
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width: 768px) {
    .slide-comp{
      min-height: 185vh;
    }
    .slide-comp-content{
      text-align: right;
      min-height: 30vh;
    }
    .slide-comp-content_txt{
      float: right;
      & > p{
        text-align: right;
      }
    }
  }
  //                      __   _  ___ ____  _  _     __
  //                     | _| / |/ _ \___ \| || |   |_ |
  //    _____ _____ _____| |  | | | | |__) | || |_   | |_____ _____ _____
  //   |_____|_____|_____| |  | | |_| / __/|__   _|  | |_____|_____|_____|
  //                     | |  |_|\___/_____|  |_|    | |
  //                     |__|                       |__|
  @media (min-width: 1024px) {
    .slide-comp{
      min-height: 190vh;
    }
  }

  //                      __   _ ____  ___   ___    __
  //                     | _| / |___ \( _ ) / _ \  |_ |
  //    _____ _____ _____| |  | | __) / _ \| | | |  | |_____ _____ _____
  //   |_____|_____|_____| |  | |/ __/ (_) | |_| |  | |_____|_____|_____|
  //                     | |  |_|_____\___/ \___/   | |
  //                     |__|                      |__|
  @media (min-width: 1280px) {
    .slide-comp{
      min-height: 130vh;
    }
  }
</style>
