<template>
  <section class="slide" id="automatisme">
    <div class="slide-aut">
      <div class="slide-original-content max1366w">
        <div>
          <h4>Automatismes intelligents</h4>
        </div>
        <div class="slide-content-txt slide-aut-content_txt">
          <p id="pin-shadow-2">
            Fokus est doté d'automatismes de remplissage et de critères de sélection adaptés au contexte.
            Ces suggestions pertinentes accélèrent et fiabilisent vos saisies.
          </p>
          <span class="shadow shadow-2">Automatismes</span>
        </div>
      </div>
      <div class="a-container">
        <div class="automatismeBg">
          <div  v-lazy:background-image="this.urlPrinc + imgUrl" class="aut-bg"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      imgUrl: '/img/automatisme.png'
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/styles/function";

  .slide-aut-content_txt{
    margin-bottom: 55px;
  }
  .automatismeBg{
    position: relative;
  }
  .aut-bg{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 80vh;
  }
  //                      __   __________ ____    __
  //                     | _| |___ /___  | ___|  |_ |
  //    _____ _____ _____| |    |_ \  / /|___ \   | |_____ _____ _____
  //   |_____|_____|_____| |   ___) |/ /  ___) |  | |_____|_____|_____|
  //                     | |  |____//_/  |____/   | |
  //                     |__|                    |__|
  @media (min-width:375px) and (min-height: 812px){
    .slide-aut{
      padding: 100px 0;
    }
    .aut-bg{
      background-size: contain;
      min-height: 30vh;
    }
  }
  //                      __   _____ __    ___    __
  //                     | _| |___  / /_  ( _ )  |_ |
  //    _____ _____ _____| |     / / '_ \ / _ \   | |_____ _____ _____
  //   |_____|_____|_____| |    / /| (_) | (_) |  | |_____|_____|_____|
  //                     | |   /_/  \___/ \___/   | |
  //                     |__|                    |__|
  @media (min-width:768px) {
    .aut-bg{
      background-size: cover;
      min-height: 40vh;
    }
  }
  //                      __   _  ___ ____  _  _     __
  //                     | _| / |/ _ \___ \| || |   |_ |
  //    _____ _____ _____| |  | | | | |__) | || |_   | |_____ _____ _____
  //   |_____|_____|_____| |  | | |_| / __/|__   _|  | |_____|_____|_____|
  //                     | |  |_|\___/_____|  |_|    | |
  //                     |__|                       |__|
  @media (min-width: 1024px) {
    .aut-bg{
      min-height: 70vh;
    }
  }
  //                      __   _ _____  __    __     __
  //                     | _| / |___ / / /_  / /_   |_ |
  //    _____ _____ _____| |  | | |_ \| '_ \| '_ \   | |_____ _____ _____
  //   |_____|_____|_____| |  | |___) | (_) | (_) |  | |_____|_____|_____|
  //                     | |  |_|____/ \___/ \___/   | |
  //                     |__|                       |__|
  @media (min-width: 1366px){
    .aut-bg{
      min-height: 100vh;
      transform: scale(0.8);
    }
    .slide-aut{
      padding-top: 100px;
      padding-bottom: 0;
    }
  }
</style>
