import { render, staticRenderFns } from "./Document.vue?vue&type=template&id=625db426&scoped=true&"
import script from "./Document.vue?vue&type=script&lang=js&"
export * from "./Document.vue?vue&type=script&lang=js&"
import style0 from "./Document.vue?vue&type=style&index=0&id=625db426&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "625db426",
  null
  
)

component.options.__file = "Document.vue"
export default component.exports